import { defineStore } from 'pinia';
import { ref } from 'vue';
import { THGCarFilterParam } from '@/interfaces/thg/THGCarFilterParam';
import { THGCarTableColumnVisibilityStatus } from '@/interfaces/thg/THGCarTableColumnVisibility';
import { THGCarTableColumn } from '@/enums/thg/THGCarTableColumn';
import { SortData } from '@/interfaces/misc';
import { SortOrder } from '@/enums/misc';

export const useTHGCarStore = defineStore(
    'thgcar',
    () => {
        const filterParam = ref<THGCarFilterParam>({
            year: 2024,
            start_create_date: undefined,
            end_create_date: undefined,
            vin: undefined,
            status: null,
            drive_code: undefined,
            license_plate: undefined,
            vehicle_class: null,
            uba_submitted_date: undefined,
            min_co2_equiv: undefined,
            max_co2_equiv: undefined,
            creation_source: null,
            user_id: undefined,
            vins: [],
            tags: [],
            not_tags: [],
            is_card: null,
            payoff_model: null,
        });

        const columnVisibility = ref<THGCarTableColumnVisibilityStatus>({
            [THGCarTableColumn.UBA_SUBMITTED_DATE]: true,
            [THGCarTableColumn.DRIVE_CODE]: false,
            [THGCarTableColumn.LICENSE_PLATE]: true,
            [THGCarTableColumn.INITIAL_REGISTRATION_DATE]: false,
            [THGCarTableColumn.DEREGISTRATION_DATE]: true,
            [THGCarTableColumn.REGISTRATION_DATE]: true,
            [THGCarTableColumn.TERMS_VERSION]: false,
            [THGCarTableColumn.FIRST_NAME]: false,
            [THGCarTableColumn.NAME]: false,
            [THGCarTableColumn.PROBABILITIES]: true,
            [THGCarTableColumn.PAYOFF_MODEL]: true,
        });

        const sortData = ref<SortData>({ field: 'created_at', order: SortOrder.DESCENDING });

        function resetFilterParams() {
            filterParam.value.year = 2024;
            filterParam.value.start_create_date = undefined;
            filterParam.value.end_create_date = undefined;
            filterParam.value.vin = undefined;
            filterParam.value.status = null;
            filterParam.value.drive_code = undefined;
            filterParam.value.license_plate = undefined;
            filterParam.value.vehicle_class = null;
            filterParam.value.uba_submitted_date = undefined;
            filterParam.value.user_id = undefined;
            filterParam.value.vins = [];
            filterParam.value.tags = [];
            filterParam.value.not_tags = [];
        }

        return {
            resetFilterParams,
            filterParam,
            columnVisibility,
            sortData,
        };
    },
    {
        persist: [
            {
                storage: sessionStorage,
                paths: ['columnVisibility'],
            },
        ],
    },
);
